import './index.scss';


$('.div_link_for_category').on('click', function () {
    localStorage.setItem('scroll', 1);
    document.location.href = $(this).attr('data-link');
});
if (localStorage.getItem('scroll') == 1) {
    const el = document.getElementById('tab_content_link');
    el.scrollIntoView();
    localStorage.setItem('scroll', 0);
}
$('.pizza_char_res').on('click', function () {
    let wrapp = $(this).parents('.product_prof');
    let current_attr = $(this).attr('data-item_attribute_id');
    let current_id = $(this).attr('data-item_id');
    let current_price = $(this).attr('data-price');


    $(wrapp).find('.btn_additional').attr('data-check', 'false');
    $(wrapp).attr('data-item_id', current_id);
    $(wrapp).find('.btn_additional').attr('data-item_attribute_id', current_attr);

    setTimeout(() => {
        $(wrapp).find('.product_summ').css('transform', 'scale(1.3)');
        $(wrapp).find('.product_summ').text(`${current_price} грн.`)
    }, 100);

    setTimeout(() => {
        $(wrapp).find('.product_summ').css('transform', 'scale(1)');
    }, 300);
});




if (localStorage.getItem('scroll') == 1) {
    var elementClick = '#second_section';
    var destination = $(elementClick).offset().top;
    $('html, body').animate({ scrollTop: destination }, 600);

    localStorage.setItem('scroll', 0);
}



$(".scroll_all").on('click', function () {
    var elementClick = $(this).attr("href");
    // // console.log(elementClick);
    var destination = $(elementClick).offset().top;
    var destContacts = (destination - 600)
    $('html, body').animate({ scrollTop: destination }, 600);
    // console.log(destContacts);
    return false;
});



$('.info_btn').on('click', function () {
    $(this).toggleClass('info_btn_active');
});


$.fn.visible = function (partial) {

    var $t = $(this),
        $w = $(window),
        viewTop = $w.scrollTop(),
        viewBottom = viewTop + $w.height(),
        _top = $t.offset().top,
        _bottom = _top + $t.height(),
        compareTop = partial === true ? _bottom : _top,
        compareBottom = partial === true ? _top : _bottom;

    return ((compareBottom <= viewBottom) && (compareTop >= viewTop));

};

function parallaxEffect(target, data_smooth) {
    var element = $(target);

    element.each(function (i, el) {

        var move = $(el);

        $(window).scroll(function (event) {
            var top = move.offset().top - $(this).scrollTop();
            if (move.visible(true)) {
                move.addClass("moving-target").css({
                    "transform": "translateY(" + top / data_smooth + "px)",
                    "-webkit-transform": "translateY(" + top / data_smooth + "px)",
                    "-moz-transform": "translateY(" + top / data_smooth + "px)"
                });
            } else {
                move.removeClass("moving-target");
            }
        });
    });
}



parallaxEffect('.header_abs_1', 5);
parallaxEffect('.header_abs_2', 6);
parallaxEffect('.header_abs_3', 7);
parallaxEffect('.header_abs_4', 8);
parallaxEffect('.header_abs_5', 9);

parallaxEffect('.header_abs_11', 5);
parallaxEffect('.header_abs_12', 6);
parallaxEffect('.header_abs_13', 7);
parallaxEffect('.header_abs_14', 8);



$('.tab_link').on('click', function () {
    // $('.tab_link').removeClass('tab_link_active');
    // $(this).addClass('tab_link_active');

    // let current_tab = $(this).attr('data-tab');
    // $('.tab_content_item').removeClass('tab_content_item_active');
    // $(`#tab_${current_tab}`).addClass('tab_content_item_active');
});

setInterval(() => {
    let current_arrow = $('.absolute_navigate_tab_arrow');
    let current_active_link = $('.tab_link_active').offset().left;
    $(current_arrow).css('left', `${current_active_link + 58}px`)
}, 100);

$('.char_res').on('click', function () {
    let wrapp = $(this).parents('.char_radiobtn');
    if ($(this).hasClass('char_second_res')) {
        // $(wrapp).find('.active_bg').removeClass('active_bg_first');
        // $(wrapp).find('.active_bg').addClass('active_bg_second');

        $(wrapp).addClass('char_radiobtn_next');
        $(wrapp).removeClass('char_radiobtn_prev');
    } else {
        // $(wrapp).find('.active_bg').addClass('active_bg_first');
        // $(wrapp).find('.active_bg').removeClass('active_bg_second');

        $(wrapp).removeClass('char_radiobtn_next');
        $(wrapp).addClass('char_radiobtn_prev');

    }
})







$('.prod_btn').on('click', function () {
    var wrap = $(this).parents('.product_prof');

    let current_oprion = $(wrap).find('.info_btn_active').parents('.info_prof');
    let mass_option = [];
    $.each($(current_oprion), function (index, value) {
        console.log('value: ', $(value));
        mass_option.push($(value).attr('data-item-attribute_value_id'));
    });

    let all_array = [{
        item_attribute_id: $(wrap).find('.btn_additional').attr('data-item_attribute_id'),
        item_attribute_value_ids: mass_option
    }];

    let item_id = $(wrap).attr('data-item_id');
    let body = {};
    if (mass_option.length != 0) {
        body = {
            "item_id": Number(item_id),
            "quantity": Number(1),
            "attributes": JSON.stringify(all_array),
        }
    } else {
        body = {
            "item_id": Number(item_id),
            "quantity": Number(1),
            "attributes": '',
        }
    }

    let all_wrap = $(wrap).parents('.tab_content_prof__wrap');
    $(all_wrap).addClass('tab_content_prof__wrap_active');
    $(all_wrap).find('.item_loader').addClass('item_loader_active');

    fetch('/api/cart_items/', {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
        }
    })
        .then(data => {
            return data.json();
        })
        .then(data => {
            console.log('data: ', data);
            // window.open('/order/');
            //   $(this).addClass('saled');
            let current_counter = Number($('.shop_counter').text());
            $('.shop_counter').css('transform', 'scale(1.3)')
            setTimeout(() => {



                $('.shop_counter').css('transform', 'scale(1)');
                $('.shop_counter').text(current_counter + 1);
            }, 150);

            setTimeout(() => {
                $(all_wrap).find('.item_loader').removeClass('item_loader_active');
                $(all_wrap).find('.success_item').addClass('success_item_active');
            }, 400);
            setTimeout(() => {
                $(all_wrap).removeClass('tab_content_prof__wrap_active');
                $(all_wrap).find('.success_item').removeClass('success_item_active');
            }, 2300);

        });

});


// fetch('/api/item_attributes/?page_size=1000&page_number=1&', {
//     method: 'GET',
//     headers: {
//     "Content-Type": "application/json",
//     "Accept": "application/json"
//     }
// })
// .then(data => {
//     return data.json();
// })
// .then(data => {
//     console.log('data: ', data);

//     data.results.forEach(function(item, i, arr) {
//         $.each($('.product_prof'),function(index,value){
//             let current_cart_id = $(value).attr('data-item_id');
//             if (item.item == current_cart_id) {
//                 $(value).find('.btn_additional').attr('data-item_id', item.id)
//             }
//         });
//     });

// // window.open('/order/');   
// });  


$('.btn_additional').on('click', function () {
    let wrap = $(this).parents('.characteriscick_prof');
    let current__wrap = $(wrap).find('.bottom_info');
    $(wrap).find('.bottom_info').toggleClass('bottom_info_active');
    let check = $(this).attr('data-check');
    if (check == "false") {
        // let body_json = {
        //     item_attribute_id: $(this).attr('data-item_attribute_id'),
        // }
        let item_attribute_id = $(this).attr('data-item_attribute_id');
        $(current__wrap).find('.info_prof').remove();
        fetch(`/api/item_attribute_values/?item_attribute_id=${item_attribute_id}`, {
            method: 'GET',
        })
            .then(data => {
                return data.json();
            })
            .then(data => {
                $(this).attr('data-check', 'true');
                console.log('data: ', data);

                data.results.forEach(function (item, i, arr) {
                    $(current__wrap)[0].appendChild(create_additional(item));
                });


            });
    }





});


function create_additional(content) {
    console.log('content: ', content);
    let info_prof = document.createElement('div');
    info_prof.classList.add('info_prof');
    info_prof.setAttribute(`data-item_attribute_id`, content.value.id);
    info_prof.setAttribute(`data-item-attribute_value_id`, content.id);

    let info_name = document.createElement('div');
    info_name.classList.add('info_name', 'color_white', 'sub_title_4', 'sub_title');
    info_name.textContent = content.value.value;

    let info_sum = document.createElement('div');
    info_sum.classList.add('info_sum', 'color_white', 'main__title_7', 'main__title');
    info_sum.textContent = content.price + ' ' + content.currency.code;

    let info_btn = document.createElement('div');
    info_btn.classList.add('info_btn', 'color_white', 'main__title_7', 'main__title');

    let img_add = document.createElement('img');
    img_add.classList.add('img_add', 'unselectable', 'absolute_center');
    img_add.setAttribute(`src`, "/static/source/img/index/add.png");

    let img_active = document.createElement('img');
    img_active.classList.add('img_active', 'unselectable', 'absolute_center');
    img_active.setAttribute(`src`, "/static/source/img/item/shape.svg");

    info_prof.appendChild(info_name);
    info_prof.appendChild(info_sum);
    info_prof.appendChild(info_btn);
    info_btn.appendChild(img_add);
    info_btn.appendChild(img_active);

    $(info_btn).on('click', function () {
        $(this).toggleClass('info_btn_active');
    });


    return info_prof;
}


